import { tns } from "tiny-slider/src/tiny-slider.module";

if (document.querySelector(".slide-wrapper")) {
    var slider0 = tns({
        container: ".slide-wrapper",
        responsive: {
            0: {
                items: 1,
            },
            768: {
                items: 1,
            },
        },
        autoplay: true,
        autoplayTimeout: 15000,
        mouseDrag: true,
        loop: true,
        autoplayHoverPause: true,
        autoplayButtonOutput: false,
        nav: false,
        autoplayButton: false,
        controls: false,
    });

    if (document.querySelector(".prev")) {
        document.querySelector(".prev").onclick = function () {
            slider0.goTo("prev");
        };
    }

    if (document.querySelector(".next")) {
        document.querySelector(".next").onclick = function () {
            slider0.goTo("next");
        };
    }
}

if (document.querySelector(".slide-wrapper-sobre")) {
    var slider3 = tns({
        container: ".slide-wrapper-sobre",
        responsive: {
            0: {
                items: 1,
            },
            992: {
                items: 2,
            },
            1200: {
                items: 3,
            },
        },
        autoplay: true,
        autoplayTimeout: 15000,
        mouseDrag: true,
        loop: true,
        autoplayHoverPause: true,
        autoplayButtonOutput: false,
        nav: false,
        autoplayButton: false,
        controls: false,
    });

    if (document.querySelector(".next-sobre")) {
        document.querySelector(".next-sobre").onclick = function () {
            slider3.goTo("next");
        };
    }
}

if (document.querySelector(".slide-wrapper-sobre-individual")) {
    var slider4 = tns({
        container: ".slide-wrapper-sobre-individual",
        responsive: {
            0: {
                items: 1,
            },
            992: {
                items: 2,
            },
            1200: {
                items: 4,
            },
        },
        autoplay: true,
        autoplayTimeout: 15000,
        mouseDrag: true,
        loop: true,
        autoplayHoverPause: true,
        autoplayButtonOutput: false,
        nav: true,
        autoplayButton: false,
        controls: false,
    });

    if (document.querySelector(".prev-sobre")) {
        document.querySelector(".prev-sobre").onclick = function () {
            slider4.goTo("prev");
        };
    }

    if (document.querySelector(".next-sobre")) {
        document.querySelector(".next-sobre").onclick = function () {
            slider4.goTo("next");
        };
    }
}

if (document.querySelector(".slide-wrapper-customers")) {
    var slider5 = tns({
        container: ".slide-wrapper-customers",
        items: 4,
        responsive: {
            0: {
                items: 1,
            },
            992: {
                items: 2,
            },
            1200: {
                items: 4,
            },
        },
        autoplay: true,
        autoplayTimeout: 15000,
        mouseDrag: true,
        loop: true,
        autoplayHoverPause: true,
        autoplayButtonOutput: false,
        nav: true,
        gutter: 40,
        autoplayButton: false,
        controls: false,
    });

    if (document.querySelector(".prev-customers")) {
        document.querySelector(".prev-customers").onclick = function () {
            slider5.goTo("prev");
        };
    }

    if (document.querySelector(".next-customers")) {
        document.querySelector(".next-customers").onclick = function () {
            slider5.goTo("next");
        };
    }
}



if (document.querySelector(".slide-wrapper-categorias")) {
    var slider5 = tns({
        container: ".slide-wrapper-categorias",
        items: 4,
        responsive: {
            0: {
                items: 1,
            },
            992: {
                items: 4,
            },
            1200: {
                items: 5,
            },
            1400: {
                items: 6,
            },
        },
        autoplay: true,
        autoplayTimeout: 15000,
        mouseDrag: true,
        loop: true,
        autoplayHoverPause: true,
        autoplayButtonOutput: false,
        nav: false,
        gutter: 40,
        autoplayButton: false,
        controls: false,
    });

    if (document.querySelector(".prev-categorias")) {
        document.querySelector(".prev-categorias").onclick = function () {
            slider5.goTo("prev");
        };
    }

    if (document.querySelector(".next-categorias")) {
        document.querySelector(".next-categorias").onclick = function () {
            slider5.goTo("next");
        };
    }
}
