(function () {

    var states = document.getElementsByClassName("estado")

    for (var i = 0; i < states.length; i++) {
        states[i].onclick = function () {

            const code = this.getAttribute('code');

            $('.estado').removeClass('active');
            this.classList.add("active");

            $(".estado-container").prop('hidden', true);

            $("#" + code).prop('hidden', false);

        }
    }

})();
