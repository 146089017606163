$(window).on("load", function () {
    if (document.querySelector("#modalNewsletter")) {
        $("#modalNewsletter").modal("toggle");
    }

    if (document.querySelector("#ancoraFiltros")) {
        $("html, body").animate(
            {
                scrollTop: $('#ancoraFiltros').offset().top - 130,
            },
            500
        );
    }

    $(".abre-dropdown").on("click", function (e) {
        e.preventDefault();
    });

    if ($(window).scrollTop() > 302) {
        $("#aparece-rolagem").css("visibility", "visible");
        $("#aparece-rolagem").css("opacity", "1");
        $("#aparece-rolagem").css("z-index", "4");
    }

    $(window).bind("scroll", function () {
        if ($(window).scrollTop() > 302) {
            $("#aparece-rolagem").css("visibility", "visible");
            $("#aparece-rolagem").css("opacity", "1");
            $("#aparece-rolagem").css("z-index", "4");
        } else {
            $("#aparece-rolagem").css("visibility", "hidden");
            $("#aparece-rolagem").css("opacity", "0");
            $("#aparece-rolagem").css("z-index", "0");
        }
    });

    if ($(".descricao-banner").length) {
        $(".descricao-banner").css("opacity", "1");
        $(".descricao-banner").css("transform", "translateY(0px)");
    }

    setTimeout(function () {
        $(".botao-whatsapp").css("animation", "shake 0.8s");
        $(".botao-whatsapp").css("opacity", "1");
    }, 1000);

    $(".animate-ancora").on("click", function () {
        $("html, body").animate(
            {
                scrollTop: $($(this).data("href")).offset().top - 100,
            },
            800
        );
    });

    try {
        $("#popup-banner-flutuante").modal("show");
    } catch (e) {}

    $(".mais-informacoes").on("click", function (e) {
        e.preventDefault();

        if ($(".descricao").css("max-height") == "none") {
            $(".descricao").css("max-height", "60px");
            $(this).text("mais informações");
        } else {
            $(".descricao").css("max-height", "none");
            $(this).text("menos informações");
        }
    });

    // if ($("#modalNotification")) {
    //     $("#modalNotification").modal("show");
    // }
    $('[data-toggle="tooltip"]').tooltip();

    $("#estado_id").change(function () {
        const codigo_uf = $(this).val();

        $("#municipio_id")
            .find("option")
            .remove()
            .end()
            .append(
                $("<option>", {
                    value: "",
                    text: "Carregando...",
                })
            );

        $.ajax({
            url: "/api/municipios/" + codigo_uf,
        }).done(function (data) {
            data = data.results;

            $("#municipio_id").find("option").remove().end();

            if (data.length > 0) {
                $("#municipio_id").append(
                    $("<option>", {
                        value: "",
                        text: "Escolha um Município",
                    })
                );

                $.each(data, function (i, item) {
                    $("#municipio_id").append(
                        $("<option>", {
                            value: item.id,
                            text: item.text,
                        })
                    );
                });
            } else {
                $("#municipio_id").append(
                    $("<option>", {
                        value: "",
                        text: "Estado não possui Municípios",
                    })
                );
            }
        });
    });

    $('[data-toggle="popover"]').popover();

    $(".legenda").change(function () {
        const url = $(this).data("url");
        const legenda = $(this).val();
        const token = $(this).data("csrf");

        const input = $(this);

        $.ajax({
            url: url,
            type: "PUT",
            data: {
                legenda: legenda,
                _token: token,
            },
        }).done(function (data) {
            if (data == "success") {
                input.addClass("is-valid");
            } else {
                input.addClass("is-invalid");
            }
        });
    });

    $(".btn-upload-multiplo-modal").click(function () {
        $("#uploadMultiploModal").modal("show");
        $("#uploadMultiploModal #uploadModalForm").attr(
            "action",
            $(this).attr("data-href")
        );

        if ($(this).attr("data-multiplo")) {
            $("#uploadMultiploModal #uploadModalForm #files").prop(
                "multiple",
                true
            );
        } else {
            $("#uploadMultiploModal #uploadModalForm #files").prop(
                "multiple",
                false
            );
        }

        if ($(this).attr("data-arquivo")) {
            $("#uploadMultiploModal #uploadModalForm #files").attr(
                "data-arquivo",
                1
            );
        } else {
            $("#uploadMultiploModal #uploadModalForm #files").attr(
                "data-arquivo",
                0
            );
        }
    });

    // $('body').delegate(".btn-upload-multiplo-modal", "click", function () {

    // });

    $("#uploadMultiploModal #uploadModalForm").on("submit", function () {
        $("#uploadMultiploModal .modal-body .sec-primary").hide();
        $("#uploadMultiploModal .modal-body .sec-secondary").show();
    });

    // function teste() {
    //
    //     const witdh = $(window).width();
    //     const href = $('.whatsapp').attr('href');
    //
    //     console.log(witdh, href);
    //
    //     if (witdh > 900) {
    //         $('.whatsapp').attr('href', 'https://web.whatsapp.com/send?text=' + $('.whatsapp').data('href'));
    //     } else {
    //         $('.whatsapp').attr('href', 'https://web.whatsapp.com/send?text=' + $('.whatsapp').data('href'));
    //     }
    //
    // }
    //
    // $(window).resize(teste());
});

function validarCNPJ(cnpj) {
    cnpj = cnpj.replace(/[^\d]+/g, "");

    if (cnpj == "") return false;

    if (cnpj.length != 14) return false;

    // Elimina CNPJs invalidos conhecidos
    if (
        cnpj == "00000000000000" ||
        cnpj == "11111111111111" ||
        cnpj == "22222222222222" ||
        cnpj == "33333333333333" ||
        cnpj == "44444444444444" ||
        cnpj == "55555555555555" ||
        cnpj == "66666666666666" ||
        cnpj == "77777777777777" ||
        cnpj == "88888888888888" ||
        cnpj == "99999999999999"
    )
        return false;

    // Valida DVs
    tamanho = cnpj.length - 2;
    numeros = cnpj.substring(0, tamanho);
    digitos = cnpj.substring(tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2) pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    if (resultado != digitos.charAt(0)) return false;

    tamanho = tamanho + 1;
    numeros = cnpj.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2) pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    if (resultado != digitos.charAt(1)) return false;

    return true;
}

window.checkCnpj = function (input) {
    if (!validarCNPJ(input.value)) {
        input.value = "";
        alert("CNPJ invalido.");
    }
};
